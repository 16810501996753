.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.select-box {
    
}

.rfip {
  margin:0px !important
}
.rfipbtn {
  min-height:53px !important
}

svg {
    margin-bottom: 0px !important;
}

.btn-mn {
    padding: 0.425rem .625rem !important;
    font-size: 0.675rem !important;
    border-radius: 0.25rem !important;
}

.modal-backdrop {
    background-color: rgba(33, 50, 91, 0.45) !important;
}

.dropcc2 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.dropcc {
  padding-top: 10px;
  padding-bottom: 10px;
}

a {
  cursor: pointer !important;
}
a.nav-link {
  cursor: pointer;
}

.navbar-brand,
.navbar-brand > img {
  width: 9.5rem !important;
}

.model-vertical-2 {
  max-width: none !important;
  margin: none !important;
}

.btn-mn-2 button {
  padding:8px;padding-left:12px;padding-right:12px
}

.dropdown-toggle:after {
  width: 0rem !important;
  height: 0rem !important;
}

.model-vertical {
  max-height: calc(100vh - 210px);
  overflow-y: "auto";
}

.model-full{height:100% !important;}

.modal-90w {
    height:100% !important;
  width: 90% !important;
  max-width: none !important;
}

.modal-550w {
  width: 100% !important;
  max-width: 750px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rbt-input {
  border: 1px solid #e0e0e0 !important;
}

.rw-widget-input {
  border: 1px solid #e0e0e0 !important;
}

.d-message {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.d-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expertise-row {
  margin-bottom: 15px;
}

.row-item-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.radio-sel {
  text-align: center;
}

.info-text {
  text-align: center;
  font-weight: 300;
  margin: 10px 0 30px;
}

.react-confirm-alert-overlay {
  z-index:99999 !important
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;
  border: 1px solid grey;
}
.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}
.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border: 5px solid gray;
  box-sizing: border-box;
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-1 {
  background: #f00;
}
.example-track.example-track-2 {
  background: #0f0;
}
.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid #000;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 10px;
}
.horizontal-slider .example-thumb {
  top: 1px;
  width: 50px;
  height: 48px;
  line-height: 38px;
}
.horizontal-slider .example-mark {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 6px);
}

.link-class-b {
  cursor: pointer;
}

.link-class {
  cursor: pointer;
}

.form-control::placeholder {
  color: #c0c0c0 !important;
  opacity: 1; /* Firefox */
}

.link-class:hover {
  color: #007bff;
}

.hidden-parent .hidden {
  display: none !important;
}

.hidden-parent:hover .hidden {
  display: inline-block !important;
}

.mention-input__control {
  padding: 10px;
}

.mention-input__input {
  width: 100%;
  border: 2px solid #c0c0c0;
  padding: 10px;
  border-radius: 6.7px;
}

.mention-input__suggestions {
  border: 2px solid #c0c0c0;
  padding: 8px;
  border-radius: 6.7px;
}

.mention-input__suggestions__item {
  margin-top: 4;
  margin-bottom: 4;
}

.mention-item {
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
